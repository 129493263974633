import React from "react";
import classNames from "classnames";

import DynamicImage from "../DynamicImage";
import Icon from "../Icon";
import * as styles from "./styles.module.scss";

type Props = {
  content?: string;
  image?: string;
  title?: string;
  isSmall?: boolean;
  link: string;
};

const InsuranceCard: React.FC<Props> = ({
  content = "",
  image = "",
  title = "",
  isSmall = false,
  link,
}) => {
  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.small]: isSmall,
      })}
    >
      <div
        className={classNames(styles.imageWrapper, {
          [styles.small]: isSmall,
        })}
      >
        <DynamicImage imageName={image} />
      </div>
      <div>
        <h3
          className={classNames(styles.title, {
            [styles.small]: isSmall,
          })}
        >
          {title}
        </h3>
        {!isSmall && <p className={styles.content}>{content}</p>}
      </div>
      {link && (
        <a href={link} target="_blank" rel="noreferrer">
          <div role="button" className={styles.buttonWrapper}>
            <Icon name="arrow" className={styles.icon} />
            <span className={styles.label}>mehr erfahren</span>
          </div>
        </a>
      )}
    </div>
  );
};

export default InsuranceCard;
