import classNames from "classnames";
import React from "react";
import Icon from "../Icon";

import * as styles from "./styles.module.scss";

type Props = {
  insuranceData?: any[];
};

const sliceIntoChunks = (arr: any[], chunkSize: number) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

const PDF: React.FC<Props> = ({ insuranceData }) => {
  return (
    <div className="hidden">
      <div id="report" className={styles.report}>
        <div className="flex justify-between items-start mb-[50px]">
          <div>
            <span className="text-12">
              VGH Versicherungs-Check – Ihr Ergebnis
            </span>
            <h1 className="text-[45px] leading-[51px]">
              Mit diesen Versicherungen
              <br />
              sichern Sie sich optimal ab
            </h1>
          </div>
          <Icon name="logoClaim" width={150} height={80} />
        </div>
        <p>
          Ihre ausgefüllten Antworten beim VGH Versicherungs-Check ergaben, dass
          folgende Versicherungen genau zu Ihnen und Ihrem Leben passen.
          <br />
          Wie geht es weiter? Gerne beraten wir Sie individuell zu Ihrem
          Ergebnis – denn nur so können wir den besten Versicherungsschutz für
          Sie rausholen. Die nächste VGH Vertretung in Ihrer Nähe können Sie
          hier finden:{" "}
          <span className="underline text-secondary pb-6 mt-2">
            vgh.de/beratersuche
          </span>
        </p>
        <div className="mt-16">
          <p className="text-lg text-gray mb-4">
            Die wichtigsten drei Versicherungen für Sie:
          </p>
          <table className={styles.table}>
            <thead className={styles.header}>
              <tr>
                <th className={classNames(styles.headerCell, styles.firstCol)}>
                  Versicherung
                </th>
                <th className={styles.headerCell}>Beschreibung</th>
              </tr>
            </thead>
            <tbody>
              {insuranceData &&
                insuranceData
                  .sort((a, b) => b.points - a.points)
                  .slice(0, 3)
                  .map((item: any) => (
                    <tr key={item.id} className={styles.row}>
                      <td className={classNames(styles.cell, styles.firstCol)}>
                        {item.name.indexOf("Kfz") >= 0
                          ? item.name
                          : item.name.replaceAll("-", "")}
                      </td>
                      <td className={styles.cell}>{item.info}</td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
        {insuranceData &&
          sliceIntoChunks(insuranceData.slice(3), 3).map(
            (insurances, index) => (
              <div key={index}>
                <>
                  <div className="html2pdf__page-break" />
                  <div className="flex justify-between">
                    <div />
                    <Icon name="logoClaim" width={150} height={80} />
                  </div>
                </>
                <div className="-mt-16">
                  <p className="text-lg text-gray mb-8">
                    Weitere Empfehlungen:
                  </p>
                  <table className={styles.table}>
                    <thead className={styles.header}>
                      <tr>
                        <th
                          className={classNames(
                            styles.headerCell,
                            styles.firstCol
                          )}
                        >
                          Versicherung
                        </th>
                        <th className={styles.headerCell}>Beschreibung</th>
                      </tr>
                    </thead>
                    <tbody>
                      {insurances
                        .sort((a, b) => b.points - a.points)
                        .map((item: any) => (
                          <tr key={item.id} className={styles.row}>
                            <td
                              className={classNames(
                                styles.cell,
                                styles.firstCol
                              )}
                            >
                              {item.name.indexOf("Kfz") >= 0
                                ? item.name
                                : item.name.replaceAll("-", "")}
                            </td>
                            <td className={styles.cell}>{item.info}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )
          )}
        {/* <>
          <div className="html2pdf__page-break" />
          <div className="flex justify-between">
            <div />
            <Icon name="logoClaim" width={150} height={80} />
          </div>
        </>
        <p className="my-5 text-xl">
          Jetzt beraten lassen – wir freuen uns auf Sie!
        </p>
        <p>
          Sprechen Sie uns gerne an – ein Beratungstermin lohnt sich immer! Denn
          nur so können wir ganz individuell auf Sie eingehen und den besten
          Versicherungsschutz für Sie rausholen.
        </p>
        <p className="underline text-secondary pb-6 mt-2">
          https://www.vgh.de/content/_resources/betreuersuche/
        </p> */}
      </div>
    </div>
  );
};

export default PDF;
