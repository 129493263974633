import React, { useContext, useEffect, useState } from "react";
import { navigate } from "gatsby";
import html2pdf from "html2pdf.js";

import InsuranceCard from "../components/InsuranceCard";
import MainLayout from "../layouts/MainLayout";
import ResultWall from "../components/ResultWall";
import SVGButton from "../components/SVGButton";
import SecondaryButton from "../components/SecondaryButton";
import Seo from "../layouts/SEO";
import { MainContext } from "../providers";
import insuranceList from "../data/insurances.json";

import FundingIcon from "../assets/icons/fundingIcon.svg";
import HealthIcon from "../assets/icons/healthIcon.svg";
import HouseIcon from "../assets/icons/houseIcon.svg";
import LawIcon from "../assets/icons/lawIcon.svg";
import MobilityIcon from "../assets/icons/mobilityIcon.svg";
import MoreIcon from "../assets/icons/moreIcon.svg";
import PDF from "../components/PDF";
import ProvisionIcon from "../assets/icons/provisionIcon.svg";

const categoryList = [
  { name: "Haftung & Recht", icon: LawIcon },
  { name: "Mobilität", icon: MobilityIcon },
  { name: "Absicherung", icon: FundingIcon },
  { name: "Vorsorge & Sparen", icon: ProvisionIcon },
  { name: "Haus & Wohnen", icon: HouseIcon },
  { name: "Gesundheit", icon: HealthIcon },
  { name: "Weiteres", icon: MoreIcon },
];

const isBrowser = typeof window !== "undefined";

const ResultPage = () => {
  const { answerData, currentQuestion } = useContext(MainContext);
  const [currentCategory, setCurrentCategory] = useState(categoryList[0].name);
  const [insuranceData, setInsuranceData] = useState([]);
  const [top3, setTop3] = useState([]);

  const generatePDF = () => {
    const element = document.querySelector("#report") as HTMLElement;
    if (isBrowser) {
      html2pdf(element, {
        html2canvas: { scale: 4 },
        filename: `VGH-Auswertung.pdf`,
        margin: 12,
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      });
    }
  };

  useEffect(() => {
    if (currentQuestion < 8) {
      navigate("/");
    }

    const tmp = [...insuranceList];

    const calcPoints = (answers: any[]) => {
      answers
        .filter(item => item.selected)
        .forEach((answer: any) => {
          if (answer?.points) {
            answer.points.map((item: any) => {
              const index = tmp.findIndex(
                insurance => insurance.id === item.id
              );
              tmp[index].points += item.points;
            });
          }
          if (answer?.childQuestions) {
            calcPoints(answer.childQuestions[0].answers);
          }
        });
    };

    answerData.map((answers: any[]) => {
      calcPoints(answers);
    });

    setInsuranceData([...tmp]);
  }, [answerData]);

  useEffect(() => {
    const tmpTop3 = insuranceData
      .filter(item => item.points > 0)
      .sort((a, b) => b.points - a.points)
      .slice(0, 3);
    setTop3(tmpTop3);
  }, [insuranceData]);

  return (
    <MainLayout>
      <Seo
        title="Auswertung"
        description="Jeder Mensch ist anders – hat seine eigenen Ziele, Wünsche, Träume. Umso wichtiger, sich und seine Liebsten so individuell wie möglich abzusichern. Wie das geht? Mit dem VGH Versicherungs-Check."
      />
      <ResultWall />
      <div className="px-4 mx-auto my-10 max-w-7xl">
        <h1 className="mb-4 text-base text-4xl md:text-5xl">
          Das ist Ihr Leben. Versichern Sie es.
        </h1>
        <p className="text-lg">
          Vielen Dank für Ihre Teilnahme! Hier ist Ihr Ergebnis.
        </p>

        <h3 className="mt-4 text-3xl text-left">
          Diese drei Versicherungen sollten in Ihrem Leben auf keinen Fall
          fehlen:
        </h3>
        <div className="flex flex-col justify-center w-full mt-2">
          <div className="flex my-12 sm:space-x-[72px] space-x-6 w-inherit overflow-x-auto overflow-y-hidden w-[calc(100vw_-_50px)] max-w-full text-center">
            {top3 &&
              top3.map((item: any) => (
                <InsuranceCard
                  content={item.info}
                  image={item.image}
                  key={item.id}
                  title={item.name}
                  link={item.link}
                />
              ))}
          </div>
        </div>
        <div className="flex flex-col justify-center w-full mt-10">
          <p className="text-lg">
            Wie geht es weiter? Sie können Ihr Ergebnis als PDF herunterladen.
            Gerne beraten wir Sie dazu auch ganz individuell. Denn ein
            Versicherungs-Check kann natürlich kein persönliches Gespräch
            ersetzen. Also, vereinbaren Sie am besten gleich einen
            Beratungstermin!
          </p>
          <div className="my-10">
            <a
              href="https://www.vgh.de/content/privat/service/betreuersuche/"
              target="_blank"
              rel="noreferrer"
              className="mr-4 inline-block"
            >
              <SecondaryButton label="Berater finden" />
            </a>
            <SecondaryButton
              label="Ergebnis als PDF speichern *"
              color="grey"
              className="inline"
              onClick={() => generatePDF()}
            />
            <span className="block pt-2 text-xs md:w-1/2">
              * Sie können Ihr Ergebnis hier herunterladen und speichern. Wenn
              Sie den Versicherungscheck schließen, können Sie auf Ihr Ergebnis
              nicht mehr zugreifen, da keine Speicherung der Daten stattfindet.
            </span>

            <h3 className="mt-20 text-3xl text-left">
              Weitere Empfehlungen im Überblick:
            </h3>
            {/* <p className="text-lg">
              Sicher haben Sie die ein oder andere Versicherung bereits
              abgeschlossen. Aber: Ein Beratungstermin lohnt sich immer. Denn
              ein Versicherungs-Check kann natürlich kein persönliches Gespräch
              ersetzen, in dem wir gezielt auf das, was Ihnen wichtig ist,
              eingehen können und den besten Versicherungsschutz für Sie
              rausholen. Also am besten gleich Beratungstermin vereinbaren.
            </p> */}
            <div className="flex flex-col justify-center">
              <div className="flex w-full space-x-4 overflow-x-auto mt-9 w-[calc(100vw_-_50px)] max-w-full">
                {categoryList.map(category => {
                  const hasItemsWithPoints = insuranceData.filter(
                    item => item.category === category.name && item.points > 0
                  );
                  if (hasItemsWithPoints.length > 0) {
                    return (
                      <SVGButton
                        Icon={category.icon}
                        key={category.name}
                        label={category.name}
                        selected={category.name === currentCategory}
                        onClick={() => setCurrentCategory(category.name)}
                      />
                    );
                  }
                  return null;
                })}
              </div>
              <div className="flex my-12 sm:space-x-[52px] space-x-6 overflow-x-auto overflow-y-hidden w-[calc(100vw_-_50px)] max-w-full">
                {insuranceData &&
                  insuranceData
                    .filter(
                      item =>
                        item.category === currentCategory &&
                        item.points > 0 &&
                        !top3.includes(item)
                    )
                    .sort((a, b) => b.points - a.points)
                    .map(item => (
                      <InsuranceCard
                        content={item.info}
                        image={item.image}
                        key={item.id}
                        title={item.name}
                        link={item.link}
                        isSmall
                      />
                    ))}
              </div>
            </div>
          </div>
          <PDF
            insuranceData={insuranceData.filter(
              item => item.points > 0 && !top3.includes(item)
            )}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default ResultPage;
