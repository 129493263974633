import React from "react";
import classNames from "classnames";

import * as styles from "./styles.module.scss";

type Props = {
  Icon: any;
  label: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  selected?: boolean;
};

const SVGButton: React.FC<Props> = ({
  Icon,
  label,
  onClick = () => {},
  selected = false,
}) => {
  return (
    <div className={styles.wrapper} role="button" onClick={onClick}>
      <div
        className={classNames(styles.iconWrapper, {
          [styles.selected]: selected,
        })}
      >
        <Icon
          className={classNames(styles.icon, {
            [styles.selected]: selected,
          })}
        />
      </div>
      <p className={styles.label}>{label}</p>
    </div>
  );
};

export default SVGButton;
